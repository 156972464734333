import React, { useState } from "react";
import Modal from "react-modal";

export const Gallery = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage(null);
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      borderRadius: "8px",
      outline: "none",
      maxWidth: "80%",
      maxHeight: "80%",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros productos</h2>
          <p>Una muestra del producto</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                    onClick={() => openModal(d.largeImage)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={d.smallImage}
                      className="img-responsive"
                      alt={d.title}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Vista previa de imagen"
        style={modalStyles}
      >
        <button onClick={closeModal} style={{ position: "absolute", top: "10px", right: "10px", background: "none", border: "none", fontSize: "1.5rem", cursor: "pointer" }}>
          &times;
        </button>
        {currentImage && (
          <img src={currentImage} alt="Vista previa" style={{ width: "100%", height: "auto" }} />
        )}
      </Modal>
    </div>
  );
};
