import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Suelas de Guatemala</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-12 col-md-6 mb-4" // Full width on mobile, half width on desktop
                >
                  <div className="feature-box">
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p className="text-justify">{d.text}</p> {/* Adjust text alignment */}
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
